<template>
  <article
    class="t-card w-100"
    data-test="tariff-card"
    itemprop="itemListElement"
    itemscope
    itemtype="http://schema.org/Product"
  >
    <div
      class="t-card__price d-flex flex-column"
      :class="{ 'justify-end': !(addressWOTariffs && passThroughProviderId !== tariff.provider.id) && !(oldClient && +providerClient === +tariff.provider?.id), 'justify-center': (addressWOTariffs && passThroughProviderId !== tariff.provider.id) || (oldClient && +providerClient === +tariff.provider?.id) }"
      itemprop="offers"
      itemscope
      itemtype="http://schema.org/Offer"
    >
      <meta
        itemprop="priceCurrency"
        content="RUB"
      >
      <meta
        itemprop="price"
        :content="tariff.payment.price"
      >
      <button
        v-if="!partner"
        class="compare_btn d-flex"
        @click="
          addCompare(tariff.paramHash + tariff.id, `${tariff.provider.name} «${tariff.name}»`)
        "
      >
        <span
          class="d-flex mask-icon mask-compare"
          :class="compareListTariffs.includes(tariff.paramHash + tariff.id) ? 'primary--text' : 'gray-dark--text'"
        />
      </button>
      <div
        v-else-if="
          tariff.payment.complexPrice && tariff.payment.complexPrice.length > 1
        "
        class="t-card__label-promo d-flex align-center justify-center"
      >
        Акция
      </div>
      <div
        v-if="
          tariff.payment.complexPrice && tariff.payment.complexPrice.length > 1
        "
        class="t-card__complex d-flex flex-column"
      >
        <div
          v-for="(price, index) in tariff.payment.complexPrice"
          :key="'complex' + index"
          class="t-card__complex-price d-flex align-center"
          :class="{
            'white--text t-card__complex-promo ': price.isPromoPrice,
            'white--text t-card__complex-overpromo':
              price.promoDisplayPercents && !price.isPromoPrice,
            't-card__complex-month': !price.promoDisplayPercents,
          }"
        >
          <template v-if="price.promoDisplayPercents">
            <p
              v-if="price.isPromoPrice"
              class="headline-4 text-no-wrap"
            >
              {{ price.price }} ₽/мес
            </p>
            <span class="d-block subhead-3">{{
              price.isPromoPrice
                ? shortMonth(price.promoDisplayPercents)
                : price.promoDisplay
            }}</span>
          </template>
          <p
            v-else
            class="title-4"
          >
            {{ price.price }} ₽
            <span class="subhead-3">с{{ price.fromMonth === 2 ? "о" : "" }} {{ price.fromMonth }}-го
              мес<span>яца</span></span>
          </p>
        </div>
      </div>
      <div
        v-else
        class="t-card__price-monthly headline-4"
      >
        {{ tariff.payment.complexPrice[0].price }} ₽/мес
      </div>
      <div class="t-card__price-connection subhead-3">
        <p class="text-no-wrap relative">
          <span style="white-space: wrap;">Подключение {{ tariff.payment.installPayment || 0 }}{{ tariff.payment.installPaymentMax ? '-' + tariff.payment.installPaymentMax : '' }}&nbsp;₽</span>&nbsp;<span
            v-if="tariff.payment.installPaymentVaries"
            class="mask-icon mask-info mask-size-md secondary-dark--text d-inline-flex text-no-wrap relative"
            style="margin-bottom: -5px; min-width: 20px; z-index: 5"
          ><LazyVTooltip
            activator="parent"
          >
            <p style="max-width: 280px">Точную стоимость специалист провайдера рассчитает индивидуально во время телефонного звонка. Конечная сумма зависит от сложности монтажа и технических условий при подключении по вашему адресу.</p>
          </LazyVTooltip></span>
        </p>
        <p
          v-if="
            tariff.payment.prepaidPayment
              && tariff.payment.prepaidPayment > tariff.payment.price
          "
        >
          {{ tariff.payment.prepaidPayment }} ₽ аванс
        </p>
      </div>
      <div
        v-if="!hideConnectionTime && tariff.connectionTime && tariff.connectionTime.displayShort && !partner"
        class="t-card__price-time subhead-3 d-flex"
      >
        <p>
          <img
            loading="lazy"
            src="@/assets/images/useful/clock.svg"
            alt="clocks"
          >
          {{
            tariff.connectionTime.displayShort
          }}
        </p>
        <p
          v-if="showFast && tariff.connectionTime.isFast"
          class="t-card__price-fast relative"
        >
          <span class="d-flex mask-icon mask-rocket" />
          Быстро
        </p>
      </div>
    </div>
    <div class="t-card__main ">
      <div
        v-if="
          tariff.markers?.recommended
            || tariff.markers?.topSale
            || tariff.markers?.optimal
            || tariff.markers?.cheaper
        "
        class="t-card__labels subhead-3 d-flex flex-wrap"
      >
        <template v-if="!partner">
          <div
            v-show="tariff.markers?.cheaper"
            class="t-card__label"
            style="background: #87B4FF"
          >
            Подешевле
          </div>
          <div
            v-show="tariff.markers?.optimal"
            class="t-card__label"
            style="background: #7dc65b"
          >
            Оптимальный
          </div>
          <div
            v-show="tariff.markers?.topSale"
            class="t-card__label"
            style="background: #ea7ac1"
          >
            Хит подключений
          </div>
        </template>
        <div
          v-show="tariff.markers?.recommended"
          class="t-card__label"
          :style="`background: ${partner ? '#db59ff' : '#7dc65b'}`"
        >
          {{ partner ? 'Хит подключений' : 'Рекомендуем' }}
        </div>
      </div>
      <div class="t-card__header">
        <div
          v-if="tariff.sortDebug"
          class="relative"
          style="z-index: 4"
        >
          <p style="margin-bottom: 16px">
            sortDebug: {{ tariff.sortDebug }}
          </p>
        </div>
        <div
          v-if="!partner"
          class="t-card__header-logo d-flex"
        >
          <img
            :alt="tariff.provider.name"
            :src="/.*\.svg/.test(tariff?.provider?.logoT?.unoptimized)
              ? tariff?.provider?.logoT?.unoptimized
              : tariff?.provider?.logoT?.url"
            itemprop="image"
            loading="lazy"
          >
          <div
            v-if="provider?.score"
            class="t-card__rate d-flex align-center"
            itemprop="aggregateRating"
            itemscope
            itemtype="http://schema.org/AggregateRating"
          >
            <div
              class="t-card__rate-score subhead-3 white--text d-flex additional--bg align-center justify-center"
              itemprop="ratingValue"
            >
              {{
                (provider?.score / 10).toFixed(1)
              }}
            </div>
            <span
              v-if="provider.ratings?.reviewsCount"
              class="t-card__rate-count subhead-3 gray-dark--text"
              itemprop="reviewCount"
            >{{ provider.ratings.reviewsCount }}</span>
            <meta
              v-else
              itemprop="reviewCount"
              content="1"
            >
          </div>
        </div>
        <span
          v-if="!partner"
          class="subhead-3 text-no-wrap secondary-dark--text d-flex flex-column"
          style="margin-bottom: 0;"
        >
          {{ tariff.provider.name }}
        </span>
        <p
          v-else
          class="title-5"
        >
          Тариф <span
            v-if="tariff.markers?.fake"
            class="red--text"
          >в архиве</span>
        </p>
        <button
          class="t-card__header-link subhead-3 text-no-wrap secondary-dark--text d-flex flex-column"
          @click="openTariff"
        >
          Тариф «{{ tariff.name }}»
        </button>
        <span
          v-if="tariff.houseType === 'HOUSE'"
          class="t-card__header-type subhead-6 gray-darker--bg"
        >В частный дом</span>
        <meta
          :content="tariff.provider.name + ' ' + tariff.name"
          itemprop="name"
        >
        <meta
          :content="
            (tariff.payment.promoDisplayPercents
              ? tariff.payment.promoDisplayPercents + ', '
              : '')
              + (tariff.services.internetSpeed
                ? 'Интернет' + ': ' + tariff.services.internetSpeed + ' Мбит/с, '
                : '')
              + (tariff.services.tvChannelsCount
                ? 'Телевидение: ' + tariff.services.tvChannelsCount + ' Каналов, '
                : '')
              + (tariff.services.mobileGigabytes
                ? 'Связь: '
                  + tariff.services.mobileGigabytes
                  + 'ГБ | '
                  + tariff.services.mobileMinutes
                  + 'мин | '
                  + tariff.services.mobileSms
                  + 'sms, '
                : '')
              + (tariff.services.cctvCameraCount
                ? 'Видеонаблюдение: '
                  + tariff.services.cctvCameraCount
                  + ' Камер'
                  + (tariff.services.cctvCameraCount % 10 === 1
                    && +tariff.services.cctvCameraCount !== 11
                    ? 'а'
                    : tariff.services.cctvCameraCount % 10 > 1
                      && tariff.services.cctvCameraCount % 10 < 5
                      && (+tariff.services.cctvCameraCount < 10
                        || +tariff.services.cctvCameraCount > 20)
                      ? 'ы'
                      : '')
                : '')
          "
          itemprop="description"
        >
        <meta
          :content="tariff.provider.name ? tariff.provider.name : ''"
          itemprop="brand"
        >
      </div>
      <div class="t-card__params">
        <div
          v-if="tariff.services.internetSpeed"
          class="t-card__params-section"
        >
          <div class="t-card__params-title title d-flex">
            <img
              class="d-flex"
              loading="lazy"
              src="~/assets/images/svg/globe.svg"
              alt="globe"
            >
            <p>
              <span
                v-if="tariff.markers?.wireless"
                class="gray-dark--text subhead-3"
              >До </span>{{ tariff.services.internetSpeed }} Мбит/с
            </p>
            <span
              v-if="tariff.techs && tariff.techs[0]"
              data-test="tech-mouseover"
              class="t-card__params-tech subhead-6"
              :class="{
                'no-pointer-events': tariff.markers?.wireless,
              }"
            >{{ tariff.techs[0].name }}
              <LazyVTooltip
                activator="parent"
              >
                <span
                  data-test="tech-tooltip"
                  class="t-card__additional-tooltip subhead-3"
                >
                  <span
                    v-if="tariff.techs[0].id === '3'"
                    class="d-block"
                  >Оптический кабель до здания{{
                    tariff.services.internetPortSpeed
                      ? `. Кабель ${tariff.services.internetPortSpeed} Мбит/с до роутера`
                      : ""
                  }}</span>
                  <span
                    v-else-if="tariff.techs[0].id === '1'"
                    class="d-block"
                  >Телефонный кабель</span>
                  <span
                    v-else-if="tariff.techs[0].id === '2'"
                    class="d-block"
                  >Оптический кабель 1024 Мбит/с до роутера</span>
                  <span
                    v-else-if="tariff.techs[0].id === '4'"
                    class="d-block"
                  >Телефонный кабель</span>
                  <span
                    v-else-if="tariff.techs[0].id === '5'"
                    class="d-block"
                  >Телевизионный кабель</span>
                  <span
                    v-else-if="tariff.techs[0].id === '6'"
                    class="d-block"
                  >Телевизионный кабель</span>
                  <span
                    v-else-if="tariff.techs[0].id === '7'"
                    class="d-block"
                  >Беспроводной модем</span>
                </span>
              </LazyVTooltip>
            </span>
            <span
              v-else-if="tariff.techs && tariff.techs[0]"
              class="t-card__params-tech subhead-6"
            >{{ tariff.techs[0].name }}</span>
          </div>
          <p class="t-card__params-descr subhead-3 gray-dark--text">
            Интернет
          </p>
        </div>
        <div
          v-if="tariff.markers?.wireless && tariff.services.wirelessInternetGb"
          class="t-card__params-section"
        >
          <div class="t-card__params-title title d-flex">
            <img
              class="d-flex"
              loading="lazy"
              src="~/assets/images/svg/radio.svg"
              alt="globe"
            >
            <p>{{ tariff.services.wirelessInternetGb }} Гб/мес.</p>
          </div>
          <p class="t-card__params-descr subhead-3 gray-dark--text">
            Объем трафика
          </p>
        </div>
        <div
          v-if="tariff.services.tvChannelsCount"
          class="t-card__params-section"
        >
          <div class="t-card__params-title title d-flex">
            <img
              class="d-flex"
              loading="lazy"
              src="~/assets/images/svg/tv.svg"
              alt="globe"
            >
            <p>
              {{ tariff.services.tvChannelsCount }} Канал{{
                counter(tariff.services.tvChannelsCount)
              }}
            </p>
          </div>
          <p class="t-card__params-descr subhead-3 gray-dark--text">
            Телевидение
          </p>
        </div>
        <div
          v-if="
            tariff.services.mobileGigabytes || tariff.services.mobileMinutes
          "
          class="t-card__params-section"
        >
          <div class="t-card__params-title title d-flex">
            <img
              class="d-flex"
              loading="lazy"
              src="~/assets/images/svg/mobile.svg"
              alt="globe"
            >
            <p v-if="tariff.services.mobileGigabytes">
              {{ tariff.services.mobileGigabytes }} Гб
            </p>
            <p v-if="tariff.services.mobileMinutes">
              {{ tariff.services.mobileMinutes }} Мин
            </p>
          </div>
          <p class="t-card__params-descr subhead-3 gray-dark--text">
            Моб. связь
          </p>
        </div>
        <div
          v-if="tariff.services.cctvCameraCount"
          class="t-card__params-section"
        >
          <div class="t-card__params-title title d-flex">
            <img
              class="d-flex"
              loading="lazy"
              src="~/assets/images/svg/camera.svg"
              alt="globe"
            >
            <p>
              {{ tariff.services.cctvCameraCount }} Камер{{
                counter(tariff.services.cctvCameraCount, "а", "ы", "")
              }}
            </p>
          </div>
          <p class="t-card__params-descr subhead-3 gray-dark--text">
            Видеонаблюдение
          </p>
        </div>
        <div
          v-for="service in tariff.subscriptions"
          :key="service.subscription.id"
          class="t-card__params-section"
        >
          <div class="t-card__params-title title-5 d-flex">
            <img
              v-if="service.subscription.iconSmall"
              :alt="service.subscription.name"
              :src="service.subscription.iconSmall"
              loading="lazy"
            >
            <img
              v-else
              class="d-flex"
              loading="lazy"
              src="~/assets/images/svg/ischecked.svg"
              alt="globe"
            >
            <p>
              {{ service.subscription.name }}
            </p>
          </div>
          <p class="t-card__params-descr subhead-3 gray-dark--text">
            {{ service.subscription.shortDescription }}
          </p>
          <div
            v-if="
              service.subscription.includes
                && service.subscription.includes.length
            "
            class="t-card__params-img"
          >
            <img
              v-for="incl in service.subscription.includes"
              :key="'incl' + incl.id"
              loading="lazy"
              :src="incl.iconSmall"
              :alt="incl.slug"
            >
          </div>
        </div>
      </div>
      <p
        v-if="routerRec"
        class="t-card__additional t-card__additional-router d-inline-block relative"
        style="margin-right: auto"
      >
        <span class="title-5 d-flex">
          <img
            class="t-card__additional-ico d-inline-flex"
            loading="lazy"
            src="~/assets/images/svg/equipment.svg"
            alt="globe"
          >
          Wi-Fi роутер</span>
        <span class="subhead-3 gray-dark--text d-flex align-center">{{
                                                                      routerRec.mainPaymentMethod.method === "RENT"
                                                                        || routerRec.mainPaymentMethod.method === "INSTALLMENT"
                                                                        || routerRec.mainPaymentMethod.method === "INCLUDE"
                                                                        ? routerRec.mainPaymentMethod.monthlyPayment || 0
                                                                        : routerRec.mainPaymentMethod.cost
                                                                    }}
          ₽{{ routerRec.mainPaymentMethod.monthlyPayment ? "/мес" : ""
          }}{{
            routerRec.mainPaymentMethod.method === "RENT" ? " в аренду" : ""
          }}
          <span data-test="devices-mouseover">
            <img
              loading="lazy"
              src="@/assets/images/svg/info.svg"
              alt="info"
              class="d-inline-flex t-card__additional-info relative"
              style="opacity: .3"
            >
            <LazyVTooltip
              activator="parent"
            >
              <span
                class="t-card__additional-tooltip subhead-3"
                data-test="devices-tooltip"
              >
                <span
                  v-if="router.mainPaymentMethod.method === 'INCLUDE'"
                  class="d-block"
                >Wi-Fi роутер от 0 ₽/мес</span>
                <span
                  v-else
                  class="d-block"
                >Wi-Fi роутер
                  {{
                    router.mainPaymentMethod.method === "RENT"
                      ? "в аренду"
                      : router.mainPaymentMethod.method === "INSTALLMENT"
                        ? "в рассрочку"
                        : ""
                  }}
                  от
                  {{
                    (router.mainPaymentMethod.monthlyPayment
                      || router.mainPaymentMethod.monthlyPayment === 0
                      ? router.mainPaymentMethod.monthlyPayment
                      : undefined) || router.mainPaymentMethod.cost
                  }}
                  ₽{{
                    router.mainPaymentMethod.monthlyPayment ? "/мес" : ""
                  }}</span>
                <span
                  v-if="tvStation"
                  class="d-block"
                >ТВ-приставка
                  {{
                    tvStation.mainPaymentMethod.method === "RENT"
                      ? "в аренду"
                      : tvStation.mainPaymentMethod.method === "INSTALLMENT"
                        ? "в рассрочку"
                        : ""
                  }}
                  от
                  {{
                    tvStation.mainPaymentMethod.monthlyPayment
                      || tvStation.mainPaymentMethod.cost
                  }}
                  ₽{{
                    tvStation.mainPaymentMethod.monthlyPayment ? "/мес" : ""
                  }}</span>
                <span class="d-block gray-dark--text last">Предоставляется при необходимости</span>
              </span>
            </LazyVTooltip>
          </span>
        </span>
      </p>
      <template v-if="tariff.info.byType">
        <p
          v-for="(info, i) in tariff.info.byType.filter(
            (_, index: number) => index < 3,
          )"
          :key="info.name + i"
          class="t-card__additional subhead-3 d-flex gray-dark--text"
        >
          <img
            class="t-card__additional-ico d-inline-flex"
            loading="lazy"
            src="~/assets/images/svg/ischecked.svg"
            alt="globe"
          >
          {{ info.name }}
        </p>
      </template>
      <template v-if="tariff.info.limited">
        <p
          v-for="(info, i) in tariff.info.limited"
          :key="info.name + i"
          class="t-card__additional-list subhead-3 d-flex black--text"
        >
          {{ info.name }}
        </p>
      </template>
    </div>

    <div
      v-if="!(((houseUrl && !tariff.fromAddress) || (oldClient && +providerClient === +tariff.provider.id) || (addressWOTariffs && passThroughProviderId !== tariff.provider.id)))"
      class="t-card__action title-4 d-flex flex-column"
    >
      <div
        class="d-flex"
        :class="houseUrl ? 't-card__action-address': 't-card__action-woaddress'"
      >
        <d-btn
          class="flex-grow title-4 t-card__action-connect"
          color="primary"
          data-test="connect-button"
          @click.stop.prevent="showOrderFast"
        >
          Подключить
        </d-btn>
        <d-btn
          v-if="!houseUrl && !partner"
          class="flex-grow title-4 t-card__action-connect"
          color="semi-primary"
          data-test="check-availability-button-tariff-card"
          @click.stop.prevent="showDialogAddress"
        >
          Проверить доступность
        </d-btn>
        <d-btn
          v-if="!woConsult"
          class="flex-grow title-4 t-card__action-cons"
          data-test="consultation-button"
          :color="!houseUrl ? 'white-primary' : 'semi-primary'"
          :style="!houseUrl ? 'padding: 0;' : ''"
          @click.stop.prevent="showOrderConsult"
        >
          Консультация
        </d-btn>
      </div>
    </div>

    <LazyVDialog
      v-model="showDialogConsult"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCallMe
        title="Получить консультацию"
        btn-title="Получить консультацию"
        subtitle="Расскажем про тариф или подберем лучше, с учетом ваших пожеланий"
        :tariff="tariff"
        @close="showDialogConsult = false"
      />
    </LazyVDialog>
    <LazyVDialog
      v-model="showDialogFast"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCallMeAutomatization
        :tariff="tariff"
        @close="showDialogFast = false"
      />
      <!--      <LazyModalCallMe -->
      <!--        title="Быстрая заявка" -->
      <!--        btn-title="Отправить заявку" -->
      <!--        subtitle="Перезвоним для уточнения деталей и согласования времени установки" -->
      <!--        :tariff="tariff" -->
      <!--        :next-modal="true" -->
      <!--        gtm-label="tariff-connect" -->
      <!--        @next-modal="nextModalFast" -->
      <!--        @close="showDialogFast = false" -->
      <!--      /> -->
    </LazyVDialog>
    <LazyVDialog
      v-model="showDialogFastSecond"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
      content-class="transparent"
      @after-leave="submit"
    >
      <LazyModalTariffFastSecondOrder
        v-if="showDialogFastSecond"
        :tariff="tariff"
        @close-modal="closeModal"
      />
    </LazyVDialog>
    <LazyVDialog
      v-model="showDialogPartners"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalTariffSegmentation
        v-if="showDialogPartners"
        :tariff="tariff"
        :title="mtsTitle"
        :subtitle="mtsSubtitle"
        @close="showDialogPartners = false"
      />
    </LazyVDialog>
  </article>
</template>

<script setup lang="ts">
import { counter } from '~/helpers'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { setCompare } from '~/composible/setFavourites'
import { useMainStore } from '~/store/main/main.store'
import { createInteraction } from '~/composible/createInteraction'
import { getOrderUrl } from '~/composible/orderUrl'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  tariff: {
    type: Object,
    required: true,
  },
  provider: {
    type: Object,
  },
})
const emit = defineEmits(['openTariffModal', 'openCallMe'])
const ctx = useNuxtApp()
const $gtm = useGtm()
const showDialogConsult = ref(false)
const showDialogFast = ref(false)
const showDialogFastSecond = ref(false)
const showDialogPartners = ref(false)
const tariffsStore = useTariffs()
const cityStore = useCities()
const $route = useRoute()
const disabledPortal = computed(() => mainStore.disabledPortal)
const addressWOTariffs = computed(() => mainStore.getAddressWOTariffs)
const hideConnectionTime = computed(() => mainStore.getHideConnectionTime)
const passThroughProviderId = computed(() => tariffsStore.getPassThroughProviderId)
const oldClient = computed(() => mainStore.getOldClient)
const providerClient = computed(() => mainStore.getProviderClient)
const partner = computed(() => mainStore.getDomainConfig)
const mainStore = useMainStore()
const automation = computed(() => mainStore.getAutomation)
const houseUrl = computed(() => cityStore.getHouseUrl)
const orderId = computed(() => tariffsStore.getOrderId)
const diProvider = computed(() => mainStore.getDiProvider)
const woConsult = computed(() => mainStore.getWoConsult && !houseUrl.value)
const showFast = computed(() => mainStore.getShowFast)

const mtsTitle = ref('')
const mtsSubtitle = ref('')

const fakeFn = () => {
  console.log(counter)
}

// const nextModalFast = () => {
//   showDialogFast.value = false
//   if (automation.value && props.tariff.markers?.enableAutomatization)
//     nextTick(() => (showDialogFastSecond.value = true))
//   else submit()
// }
const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: $route.name !== 'city-providers-provider', label: 'tariff' })
}

const openTariff = () => {
  $gtm.trackEvent({
    event: 'trackEvent',
    category: 'tariffmodal',
    action: 'open',
    label: 'tariffclick',
  })
  goTo()
}

const goTo = () => {
  // showDialogTariff.value = true
  emit('openTariffModal', props.tariff, props.provider)
  // ctx.$event('gtmSendEvent', {
  //   event: 'trackEvent',
  //   category: 'view',
  //   action: 'tariff',
  //   label: 'tariff_card',
  // })
  // window.open(url, '_blank')
}
const { compareListTariffs, addCompare } = setCompare()

const shortMonth = (text: string) => {
  if (disabledPortal.value) return text
  else return text ? text.replace(/мес.*/, 'мес') : text
}

const router = computed(() => {
  return (
    props.tariff.devices
    && props.tariff.devices.find(
      el =>
        el.device.kind === 'ROUTER'
        && el.device.wifi
        && el.device.wifi !== 'NO',
    )
  )
})
const routerRec = computed(() => {
  return (
    props.tariff.devices
    && (props.tariff.devices.find(
      el =>
        el.device.kind === 'ROUTER'
        && el.device.wifi
        && el.device.wifi !== 'NO'
        && el.recommended,
    )
    || props.tariff.devices.find(
      el =>
        el.device.kind === 'ROUTER'
        && el.device.wifi
        && el.device.wifi !== 'NO',
    ))
  )
})
const tvStation = computed(() => {
  return (
    props.tariff.devices
    && props.tariff.devices.find(el => el.device.kind === 'TV_BOX')
  )
})

const { uI } = createInteraction()

const closeModal = () => {
  showDialogFast.value = false
  showDialogFastSecond.value = false
}

const submit = async () => {
  if (automation.value)
    $gtm.trackEvent({
      event: 'trackEvent',
      category: 'checkout',
      action: 'rejection',
      label: props.tariff?.markers?.disabledAutomatizationReasons?.join(', '),
      displayReasons: props.tariff?.markers?.disabledAutomatizationReasonsDisplay?.join(', '),
    })
  await uI(
    orderId.value,
    !!houseUrl.value,
    undefined,
    undefined,
    undefined,
    undefined,
  )
  getOrderUrl()
}

const showOrderConsult = () => {
  if (diProvider.value) {
    showDialogPartners.value = true
    mtsTitle.value = 'Получить консультацию'
    mtsSubtitle.value
      = 'Расскажем про тариф или подберем лучше, с учетом ваших пожеланий'
    return
  }
  showDialogConsult.value = true
}
const showOrderFast = () => {
  if (diProvider.value) {
    showDialogPartners.value = true
    mtsTitle.value = 'Быстрая заявка'
    mtsSubtitle.value
      = 'Перезвоним для уточнения деталей и согласования времени установки'
    return
  }
  emit('openCallMe', props.tariff)
}
</script>

<style scoped lang="scss">
.t-card {
  display: grid;
  grid-template-columns: auto 347px;
  grid-template-rows: 40fr auto;
  position: relative;
  margin-bottom: 12px;
  border: 1px solid color(gray-light);
  padding: 0;
  border-radius: 16px;
  transition: box-shadow 0.24s;
  background: color(white);
  &:hover {
    box-shadow: 4px 4px 15px rgba(3, 5, 32, 0.1);
  }
  @media (max-width: getBreakpoint(tablet)) {
    display: block;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.1);
    align-items: flex-start;
    &:hover {
      box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.1);
    }
  }

  .mask-rocket {
    mask-image: url('@/assets/images/svg/rocket.svg');
  }
  .mask-compare {
    mask-image: url('@/assets/images/svg/compare.svg');
  }
  .mask-info {
    mask-image: url('@/assets/images/svg/info.svg');
  }

  &__labels {
    margin-left: 24px;
    margin-top: -14px;
    margin-bottom: -10px;
    color: color(white);
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: 16px;
      margin-top: -12px;
      margin-bottom: -6px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-left: 12px;
      margin-bottom: -4px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-left: 8px;
    }
  }
  &__label {
    padding: 2px 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 21px;
    margin-right: 4px;
    margin-top: 4px;

    @media (max-width: getBreakpoint(tablet)) {
      height: 18px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      padding: 1px 5px;
      height: 17px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 16px;
    }

    &-promo {
      position: absolute;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
      right: 24px;
      top: 0;
      width: 53px;
      height: 27px;
      background: var(--price);
      color: color(white);
      padding: 0 4px 4px;
      font-size: fontSize(paragraph);
      @media (max-width: getBreakpoint(tablet)) {
        right: 16px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        transform: scale(95%);
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        right: 14px;
        transform: scale(90%);
      }
    }
  }
  &__main {
    grid-row: 1/3;
    grid-column: 1/2;
    position: relative;
  }

  &__header {
    padding: 24px 0 0 24px;
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px 0 0 16px;
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      padding: 12px 0 0 12px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 12px 0 0 8px;
    }
    &-type {
      padding: 0 4px;
      border-radius: 4px;
    }
    &-logo {
      margin-bottom: 4px;
      img {
        width: 180px;
        height: 45px;
        object-fit: contain;
        object-position: left center;
        @media (max-width: getBreakpoint(tablet)) {
          width: 128px;
          height: 32px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 118px;
          height: 30px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 96px;
          height: 24px;
        }
      }
    }
    &-link {
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: getBreakpoint(tablet)) {
        color: color(gray-dark);
        display: block;
        max-width: calc(58% - 16px);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        max-width: calc(58% - 12px);
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        max-width: calc(58% - 8px);
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
    }
  }

  &__rate {
    margin-left: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: 8px;
    }
    &-score {
      border-radius: 4px;
      width: 25px;
      height: 24px;
      margin-right: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        width: unset;
        height: 18px;
        padding: 0 2px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 16px;
      }
    }

    &-count {
    }
  }

  &__params {
    margin-left: 24px;
    margin-bottom: 24px;
    max-width: 239px;
    min-width: 239px;
    display: inline-flex;
    flex-direction: column;
    float: left;
    @media (max-width: getBreakpoint(wide)) {
      height: unset;
      float: none;
    }
    @media (max-width: getBreakpoint(tablet)) {
      display: flex;
      max-width: unset;
      min-width: unset;
      flex: unset;
      float: none;
      margin-left: 16px;
      margin-right: 0;
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-left: 10px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-left: 8px;
    }

    &-img {
      margin-left: 32px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: 30px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-left: 28px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-left: 26px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        object-fit: contain;
        @media (max-width: getBreakpoint(tablet)) {
          width: 22px;
          height: 22px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 20px;
          height: 20px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 18px;
          height: 18px;
        }
      }
    }
    &-section {
      &:not(:last-child) {
        margin-bottom: 8px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-bottom: 4px;
        }
      }
    }
    &-title {
      @media (max-width: getBreakpoint(tablet)) {
        display: flex;
        align-items: center;
      }
      .mask-icon, img {
        margin-top: 2px;
        width: 24px;
        height: 24px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 22px;
          height: 22px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 20px;
          height: 20px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 18px;
          height: 18px;
        }
      }
      & > p {
        margin-left: 4px;
      }
    }
    &-tech {
      position: relative;
      z-index: 5;
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
      border: 1px solid color(gray-b);
      color: color(gray-b);
      border-radius: 4px;
      padding: 0 4px;
      margin-left: 8px;
      &.active {
        border: 1px solid color(additional-light);
        color: color(additional-light);
      }
      &:before{
        content: '';
        position: absolute;
        left: -8px;
        top: -8px;
        right: -8px;
        bottom: -8px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: 6px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-left: 4px;
      }
    }

    &-descr {
      margin-left: 28px;
      line-height: 1.3;
      @media (max-width: getBreakpoint(tablet)) {
        margin-top: -4px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-left: 24px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-left: 22px;
      }
    }
  }

  &__additional {
    padding-left: 24px;
    margin-bottom: 4px;
    &-list {
      padding-left: 44px;
      margin-bottom: 4px;
      position: relative;
      @media (max-width: getBreakpoint(tablet)) {
        padding-left: 42px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        padding-left: 36px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        padding-left: 38px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 24px;
        width: 8px;
        height: 8px;
        background-color: color(black);
        border-radius: 50%;
        top: 5px;
        @media (max-width: getBreakpoint(mobile-lg)) {
          left: 18px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          top: 4px;
          left: 14px;
          width: 7px;
          height: 7px;
        }
      }
    }
    &:last-child {
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 0;
      }
    }
    @media (max-width: getBreakpoint(wide)) {
      max-width: unset;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 0;
      max-width: unset;
      padding-left: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      padding-left: 10px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding-left: 8px;
    }

    &-ico {
      margin-right: 4px;
      margin-top: -1px;
    }

    .subhead-3 {
      margin-top: -4px;
      padding-left: 28px;
    }

    &-info {
      margin-left: 4px;
      z-index: 2;
      width: 16px;
      height: 16px;
      &:before {
        content: "";
        position: absolute;
        left: -12px;
        right: -12px;
        top: -12px;
        bottom: -12px;
      }
    }

    &-tooltip {
      span.last {
        margin-top: 16px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-top: 4px;
        }
      }
    }
    &-router {
      margin-bottom: 16px;
      margin-top: 8px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 4px;
        margin-top: 0px;
      }
    }
  }

  &__price {
    grid-column: 2/3;
    grid-row: 1/2;
    border-left: 1px solid color(gray-light);
    padding: 56px 21px 0;
    position: relative;
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: 2px;
      float: right;
      padding: 48px 8px 8px 8px;
      position: static;
      min-width: 42%;
      max-width: 42%;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 44px 8px 0 8px;
    }

    .compare_btn {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 28px;
        height: 28px;
        img {
          width: 28px;
          height: 28px;
        }
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 26px;
        height: 26px;
        img {
          width: 26px;
          height: 26px;
        }
      }
    }

    &-monthly {
      padding: 0 8px;
      border-radius: 8px;
      background: color(gray-p);
      margin-right: auto;
      margin-bottom: 8px;
      @media (max-width: getBreakpoint(tablet)) {
        padding: 0 4px;
      }
    }

    &-connection {
      margin-right: auto;
      //margin-bottom: 4px;
      background: color(gray-darker);
      padding: 0 4px;
      border-radius: 4px;
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 4px;
        //margin-bottom: 0;
      }
      p {
        background: color(gray-darker);
      }
    }

    &-time {
      margin-top: -12px;
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: column;
        margin-bottom: 0;
        margin-top: -4px;
      }
      img {
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 18px;
          height: 18px;
          top: 0px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 16px;
          height: 16px;
          top: 1px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 14px;
          height: 14px;
        }
      }
      p:first-child {
        margin-right: 4px;
        padding-left: 22px;
        position: relative;
        @media (max-width: getBreakpoint(tablet)) {
          padding-left: 20px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          padding-left: 18px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          padding-left: 16px;
        }
      }
    }
    &-fast {
      margin-right: auto;
      padding-left: 24px;
      border-radius: 4px;
      color: #109836;
      @media (max-width: getBreakpoint(tablet)) {
        margin-top: 4px;
        padding-left: 22px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        padding-left: 20px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        padding-left: 18px;
      }
      .mask-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        mask-size: 20px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 18px;
          height: 18px;
          mask-size: 18px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 16px;
          height: 16px;
          mask-size: 16px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 14px;
          height: 14px;
          mask-size: 14px;
        }
      }
    }

    &--connect {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0 16px 16px 0;
      backdrop-filter: blur(1px);
      background-color: rgba(color(white), 0.7);
      z-index: 3;
      @media (max-width: getBreakpoint(tablet)) {
        border-radius: 16px;
      }

      &-wrap {
        background-color: color(white);
        padding: 32px 32px 24px 24px;
        justify-content: center;
        flex-grow: 1;
        border-radius: 0 16px 16px 0;
        box-shadow: 4px 4px 30px rgba(3, 5, 32, 0.1);
        @media (max-width: getBreakpoint(tablet)) {
          padding: 24px 16px;
          border-radius: 12px;
          flex-grow: 0;
          position: relative;
        }
      }

      &:deep(.d-btn) {
        height: 40px;
        margin-top: 8px;
      }

      &-title {
        margin-bottom: 16px;
        @media (max-width: getBreakpoint(mobile-md)) {
          margin-bottom: 12px;
        }
      }

      &-close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        &:deep(circle) {
          fill: color(gray-light);
        }
      }

      &-tel {
        margin-right: auto;
        transition: color 0.24s;
        margin-bottom: -3px;
        @media (max-width: getBreakpoint(mobile-md)) {
          margin-bottom: 12px;
        }
        &:hover {
          color: color(primary);
        }
      }
      &-free {
      }
    }
  }

  &__complex {
    margin-bottom: 8px;
    &-promo {
      margin-bottom: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: column;
        align-items: flex-start;
      }
      p {
        background: var(--price, color(gradient));
        border-radius: 4px;
        padding: 0 8px;
        margin-right: 4px;
        margin-bottom: 4px;
        @media (max-width: getBreakpoint(tablet)) {
          border-radius: 5px;
          padding: 0 5px;
        }
      }
      span {
        background: var(--price, color(gradient));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-overpromo {
      background: color(gradient);
      position: relative;
      border-radius: 4px;
      margin-right: auto;
      margin-bottom: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 8px;
      }
      span {
        position: relative;
        padding: 2px 4px;
        background: color(gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (max-width: getBreakpoint(tablet)) {
          padding: 0 3px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background: color(white);
        border-radius: 3px;
      }
    }
    &-month {
      margin-bottom: 4px;
      span {
        @media (max-width: getBreakpoint(tablet)) {
          span {
            display: none;
          }
        }
      }
    }
  }

  &__action {
    grid-column: 2/3;
    grid-row: 2/3;
    position: relative;
    z-index: 2;
    padding: 0 21px 21px 21px;
    border-left: 1px solid color(gray-light);
    @media (max-width: getBreakpoint(tablet)) {
      border-left: unset;
      z-index: 3;
      width: 100%;
      padding: 16px 16px 12px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      padding: 16px 12px 12px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 16px 8px 12px;
    }
    &-cons {
      border-color: transparent !important;
      margin-top: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-top: 8px;
      }
      &:focus {
        box-shadow: none;
      }
      &.d-btn--semi-primary {
        padding: 0;
        background: transparent !important;
        @media (max-width: getBreakpoint(tablet)) {
          border: 1px solid currentColor !important;
          flex: 0 0 calc(50% - 4px);
          margin-top: 0;
          margin-right: 8px;
          margin-bottom: 8px;
          height: 44px !important;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          height: 42px !important;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          height: 40px !important;
        }
      }
      &.d-btn--white-primary {
        //padding: 0;
        //background: transparent !important;
        //@media (max-width: getBreakpoint(tablet)) {
        //  flex: 0 0 calc(50% - 4px);
        //  margin-top: 0;
        //  margin-right: 8px;
        //  margin-bottom: 8px;
        //  height: 44px !important;
        //}
        //@media (max-width: getBreakpoint(mobile-lg)) {
        //  height: 42px !important;
        //}
        //@media (max-width: getBreakpoint(mobile-md)) {
        //  height: 40px !important;
        //}
      }
    }
    &-connect {
      height: 54px;
      padding: 8px 16px;
      position: relative;
      @media (max-width: getBreakpoint(tablet)) {
        height: 44px !important;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 42px !important;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px !important;
      }
      &:not(:first-child) {
        margin-top: 12px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-top: 8px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: -8px;
        right: -8px;
        top: -8px;
        bottom: -8px;
      }
    }
    &-address {
      flex-direction: column;
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: row-reverse;
        margin-bottom: -8px;
      }
    }
    &-woaddress {
      flex-direction: column;
    }
  }
}
</style>
